enum TextLabel {
  LOGO_LABEL = 'logo-label',
  LOGO_TOOLTIP = 'logo-tooltip',
  LIVE_INDICATOR_TEXT = 'live-indicator-text',
  LIVE_SYNC_LABEL = 'live-sync-button-label',
  LIVE_SYNC_TOOLTIP = 'live-sync-button-tooltip',
  ENTER_FULL_SCREEN_LABEL = 'enter-full-screen-label',
  ENTER_FULL_SCREEN_TOOLTIP = 'enter-full-screen-tooltip',
  EXIT_FULL_SCREEN_LABEL = 'exit-full-screen-label',
  EXIT_FULL_SCREEN_TOOLTIP = 'exit-full-screen-tooltip',
  ENTER_PICTURE_IN_PICTURE_LABEL = 'enter-picture-in-picture-button-label',
  ENTER_PICTURE_IN_PICTURE_TOOLTIP = 'enter-picture-in-picture-button-tooltip',
  EXIT_PICTURE_IN_PICTURE_LABEL = 'exit-picture-in-picture-button-label',
  EXIT_PICTURE_IN_PICTURE_TOOLTIP = 'exit-picture-in-picture-button-tooltip',
  PLAY_CONTROL_LABEL = 'play-control-label',
  PAUSE_CONTROL_LABEL = 'pause-control-label',
  PROGRESS_CONTROL_LABEL = 'progress-control-label',
  PROGRESS_CONTROL_VALUE = 'progress-control-value',
  UNMUTE_CONTROL_LABEL = 'unmute-control-label',
  UNMUTE_CONTROL_TOOLTIP = 'unmute-control-label',
  MUTE_CONTROL_LABEL = 'mute-control-label',
  MUTE_CONTROL_TOOLTIP = 'mute-control-tooltip',
  VOLUME_CONTROL_LABEL = 'volume-control-label',
  VOLUME_CONTROL_VALUE = 'volume-control-value',
  DOWNLOAD_BUTTON_LABEL = 'download-button-label',
  DOWNLOAD_BUTTON_TOOLTIP = 'download-button-tooltip',
}

export default TextLabel;
